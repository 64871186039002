<template>
  <div id="Post">
    <Hero v-if="post" :src="post.image.url">
      <h1 class="title">
        {{ post.title }}
      </h1>
    </Hero>
    <Wrap class="lg">
      <div v-if="post" class="content">
        <Markdown :hasStyles="true" :markdown="post.content" />
      </div>
    </Wrap>
    <SectionNews :skipPostId="skipPostId" title="Kitos naujienos" />
  </div>
</template>

<script>
import Hero from './components/landing/Hero'
import Meta from 'views/mixins/Meta'
import SectionNews from './components/SectionNews'
import axios from 'axios'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [
    Meta(
      {
        inner: 'Naujiena',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: { Hero, Markdown, SectionNews },
  data() {
    return {
      post: null,
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    },
    skipPostId() {
      if (!this.post) return 0
      return this.post.id
    },
  },
  methods: {
    async fetchPost() {
      const response = await axios
        .get('https://api.suninvestmentgroup.com/postSb', { params: { slug: this.slug } })

      this.post = response.data[0]
    },
  },
  watch: {
    slug: {
      immediate: true,
      handler() {
        this.fetchPost()
      },
    },
  },
  async mounted() {
    await this.fetchPost()
  },

}
</script>

<style lang="scss">
#Post {
  background: $white-ter;

  .Hero {
    .title {
      font-size: $h3;
      @include md {
        font-size: $h2;
      }
      @include lg {
        font-size: $h1;
      }
    }

    img {
      filter: opacity(0.3);
    }
  }

  > .Wrap .content {
    background: $white-ter;
    margin: 5rem 0;

    img {
      text-align: center;
    }

    p {
      margin-bottom: 2rem;
      // text-indent: 1rem;
    }
  }

  .SectionNews {
    background-color: $white;
  }
}
</style>
